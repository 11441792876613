import React, { useEffect, useState } from 'react'

import { motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { urlFor, client } from '../../client'
import AppWrapper from '../../wrapper/AppWrapper'
import MotionWrap from '../../wrapper/MotionWrap'
import './Skills.scss';

const Skills = () => {

  const [experiences, setExperience] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      // console.log(data)
      setExperience(data);

    });
    client.fetch(skillsQuery).then((data) => {
      setSkills(data);

    });
  }, []);

  return (
    <div className="app__skills-main-container">
      <h2 className='head-text'>Skills & <span>Experiences</span></h2>

      <div className='app__skills-container'>
        <motion.div
          className='app__skills-list'

        >
          {skills?.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div key={skill.name} className='app__flex' style={{ backgroundColor: skill.bgColor }}>
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className='app-text'>{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work) => (
                  <div key={work.name}>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-tooltip-id={work.name}

                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      key={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </div>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  )
}


export default AppWrapper(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  "app__whitebg"
) 
