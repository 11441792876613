import email from '../assets/email.webp';
import mobile from '../assets/mobile.webp';
import css from '../assets/css.webp';
import figma from '../assets/figma.webp';
import git from '../assets/git.webp';
import html from '../assets/html.webp';
import javascript from '../assets/javascript.webp';
import node from '../assets/node.webp';
import react from '../assets/react.webp';
import mongo from '../assets/mongo.webp'
import sass from '../assets/sass.webp';
import express from '../assets/express.webp'
import fullstack_about01 from '../assets/fullstack_about01.webp'
import frontend_about01 from '../assets/frontend_about01.webp'
import backend_about01 from '../assets/backend_about01.webp'
import responsivewebdesign_about01 from '../assets/responsivewebdesign_about01.webp'
import merkstack_about01 from '../assets/mern_about01.webp'
import webdeveloment_about01 from '../assets/webdeveloment_about01.webp'
import profile from '../assets/profile.webp';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.webp';
import github from '../assets/github.webp';
import firebase from '../assets/fire base.webp';
import tailwind from '../assets/tailwind.webp';
import chartJs from '../assets/chartjs.webp';
import boostrap from '../assets/boostrap.webp';





export default {
  email,
  mobile,
  mongo,
  css,
  figma,
  git,
  html,
  javascript,
  node,
  react,
  sass,
  profile,
  circle,
  logo,
  express,
  frontend_about01,
  backend_about01,
  fullstack_about01,
  responsivewebdesign_about01,
  merkstack_about01,
  webdeveloment_about01,
  github,
  firebase,
  tailwind,
  chartJs,
  boostrap
};